//
// === Components ===
//

// const asset = `{
//     _type,
//     _key,
//     alt,
//     caption,
//     'dimensions': image.asset->metadata.dimensions,
//     'url': image.asset->url,
//   }`

const productCard = `
'productPrice': content.main.productPrice,
'fixedPrice': content.main.fixedPrice,
'isHidden': content.main.isHidden,
'productPrice': content.main.productPrice,
`

const menuQuery = `
*[_type == "menus"] {
    ...,
    items[] {
    ...,
    _type == 'internalLink' => {
        ...,
        link->
    }
  }
}
`

const moduleQuery = `
  _type == 'postGrid' => {
    ...,
    posts[]->
  },
  _type == 'productGrid' => {
    ...,
    products[]-> {
        ...,
        ${productCard}
      },
  }
`

const pageQuery = `
  ...,
  content {
    ...,
    main {
      ...,
      modules[] {
        ...,
        ${moduleQuery}
      }
    }
  }
`

module.exports = {
  pageQuery,
  menuQuery,
}
