import React from 'react'
import { Router } from '@reach/router'

import { useLoads } from 'react-loads'

import Page from 'src/templates/page'

import sanityClient from '@sanity/client'

import { pageQuery } from 'src/utils/queries'

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  useCdn: false,
  withCredentials: true,
})

const PreviewPage = ({ document }) => {
  const [doc, setDoc] = React.useState(null)

  const queryDraft = `*[_id == "${document}"]  {
    ...,
  }`

  const queryPreviewPage = `*[_id == "${document}"]  {
    ${pageQuery}
  }`

  const handlePreviewFetch = React.useCallback(
    () =>
      client.fetch(queryDraft).then((response) => {
        switch (response[0]._type) {
          case 'page':
            client.fetch(queryPreviewPage).then((res) => {
              setDoc(res[0])
            })
            break
          default:
            break
        }
      }),
    [queryDraft, queryPreviewPage]
  )

  const { isResolved, isPending, isReloading, load } = useLoads(
    'handlePreviewFetch',
    handlePreviewFetch,
    {
      defer: true,
    }
  )

  React.useEffect(() => {
    load()
  }, [0])

  const renderPreview = () => {
    if (doc) {
      switch (doc._type) {
        case 'page':
          return <Page pageContext={doc.content} preview={true} />
        default:
          break
      }
    }
  }
  return (
    <>
      {(isPending || isReloading) && (
        <div className="ac">
          <span>Loading</span>
        </div>
      )}
      {isResolved && !isPending && renderPreview()}
    </>
  )
}

const Previews = () => {
  return (
    <div>
      <Router>
        <PreviewPage path="/previews/:document" />
      </Router>
    </div>
  )
}

export default Previews
